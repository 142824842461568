@import "../../../css/beekit/_colors.scss";
@import "./_spacebar-icons.scss";

// unified palette
// try to use these colors when possible
$bg-color: #25262d; //
$bg-color-secondary: $dark-850;
// $accent-bg-color: $bg-color;
$base-color: $bg-color;
$base-text-color: #f0f0f1;
$header-panel-bg-color: #424650; // $bg-color + (11,12,13)
$header-panel-border-color: #000000; // inherited
$header-panel-text-primary-color: #b9bec6; // inherited
$header-panel-text-secondary-color: #c8c8cd; // inherited
$text-primary-color: #f0f0f1;
$text-secondary-color: #d0d1d8;
$search-bg-color: #3a3c46; // $bg-color + (3, 2, 3)
$search-placeholder-color: rgba(208, 209, 216, 0.6);
$room-highlight-color: $bg-color;
$favourites-bg-color: $bg-color;
$favorite-tiles-bg: #5e5f64;
$unreads-grouped-bg-color: $dark-850;
$bp-unreads-toggle-text: $dark-400;
$app-controls-color: #000;

// typical text (dark-on-white in light skin)
$primary-fg-color: $text-primary-color;
$primary-content: $primary-fg-color;
$primary-bg-color: $bg-color;
$background: hsla(216, 21%, 14%, 1);
$muted-fg-color: $header-panel-text-primary-color;

$chat-network-content-bg: $bg-color;

// additional text colors
$secondary-fg-color: #a9b2bc; // inherit
$tertiary-fg-color: #8e99a4; // inherit

// used for dialog box text
$light-fg-color: $header-panel-text-secondary-color;

// used for focusing form controls
$focus-bg-color: $bg-color-secondary;

$other-user-pill-bg-color: $room-highlight-color;
$rte-room-pill-color: $room-highlight-color;
$rte-group-pill-color: $room-highlight-color;

// informational plinth
$info-plinth-bg-color: $header-panel-bg-color;
$info-plinth-fg-color: #888;

$preview-bar-bg-color: $header-panel-bg-color;

$tagpanel-bg-color: rgba(38, 39, 43, 0.82);
$groupFilterPanel-bg-color: $tagpanel-bg-color;
$inverted-bg-color: $base-color;

// used by AddressSelector
$selected-color: $room-highlight-color;

// selected for hoverover & selected event tiles
$event-selected-color: #21262c;

// used for the border of input text fields
$input-border-color: rgba(231, 231, 231, 0.2);
$input-darker-bg-color: $search-bg-color;
$input-darker-fg-color: $search-placeholder-color;
$input-lighter-bg-color: #f2f5f8;
$input-lighter-fg-color: $input-darker-fg-color;
$input-focused-border-color: #238cf5;
$input-valid-border-color: $accent-color;
$input-invalid-border-color: $warning-color;

// apart from login forms, which have stronger border
$strong-input-border-color: rgba(249, 248, 248, 0.2);

$field-focused-label-bg-color: $bg-color;

$instruction-num-color: #fff;

// scrollbars
$scrollbar-thumb-color: rgba(255, 255, 255, 0.2);
$scrollbar-track-color: transparent;

// context menus
$menu-border-color: $header-panel-border-color;
$menu-bg-color: $header-panel-bg-color;
$menu-box-shadow-color: $bg-color;
$menu-selected-color: $room-highlight-color;

$avatar-initial-color: $space-60;
$avatar-bg-color: $bg-color;
$avatar-icon-filter: invert(1);

$h3-color: $primary-fg-color;

$dialog-title-fg-color: $base-text-color;
$dialog-backdrop-color: #000;
$dialog-shadow-color: rgba(0, 0, 0, 0.48);
$dialog-close-fg-color: #9fa9ba;

$dialog-background-bg-color: $header-panel-bg-color;
$lightbox-background-bg-color: #000;

$settings-grey-fg-color: #a2a2a2;
$settings-profile-placeholder-bg-color: #21262c;
$settings-profile-overlay-placeholder-fg-color: #454545;
$settings-profile-button-bg-color: #e7e7e7;
$settings-profile-button-fg-color: $settings-profile-overlay-placeholder-fg-color;
$settings-subsection-fg-color: $text-secondary-color;

$topleftmenu-color: $text-primary-color;
$roomheader-color: $text-primary-color;
$roomheader-bg-color: rgba(37, 38, 45, 0.88);
$roomheader-button-color: #c3c4c5;
$roomheader-addroom-bg-color: rgba(92, 100, 112, 0.3);
$roomheader-addroom-fg-color: $text-primary-color;
$tagpanel-button-color: $header-panel-text-primary-color;
$groupheader-button-color: $header-panel-text-primary-color;
$rightpanel-button-color: $header-panel-text-primary-color;
$icon-button-color: #8e99a4;
$roomtopic-color: $text-secondary-color;
$eventtile-meta-color: $roomtopic-color;

$header-divider-color: $header-panel-text-primary-color;
$composer-e2e-icon-color: $header-panel-text-primary-color;

// this probably shouldn't have it's own colour
$voipcall-plinth-color: #21262c;

// ********************

$theme-button-bg-color: #e3e8f0;

$room-list-preview-color: #d0d1d8;
$roomlist-button-bg-color: rgba(
    141,
    151,
    165,
    0.2
); // Buttons include the filter box, explore button, and sublist buttons
$roomlist-filter-active-bg-color: $bg-color;
$roomlist-bg-color: rgba(33, 38, 44, 0.9);
$roomlist-header-color: $tertiary-fg-color;
$roomsublist-divider-color: $primary-fg-color;
$roomsublist-skeleton-ui-bg: linear-gradient(180deg, #3e444c 0%, #3e444c00 100%);

$tagpanel-divider-color: $roomlist-header-color;

$roomtile-preview-color: $secondary-fg-color;
$roomtile-default-badge-bg-color: #61708b;
$roomtile-selected-bg-color: #d0d1d829;
$roomtile-hover-bg-color: rgba(208, 209, 216, 0.16);
$roomtile-title-color: #f0f0f1;

// ********************

$notice-secondary-color: $roomlist-header-color;

$panel-divider-color: transparent;

$widget-menu-bar-bg-color: $header-panel-bg-color;
$widget-body-bg-color: rgba(141, 151, 165, 0.2);

// event tile lifecycle
$event-sending-color: $text-secondary-color;

// event redaction
$event-redacted-fg-color: #606060;
$event-redacted-border-color: #000000;

$event-highlight-fg-color: $warning-color;
$event-highlight-bg-color: #25271f;

// event timestamp
$event-timestamp-color: $text-secondary-color;

$link-preview-text-color: #949598;

// Tabbed views
$tab-label-fg-color: $text-primary-color;
$tab-label-active-fg-color: $text-primary-color;
$tab-label-bg-color: transparent;
$tab-label-active-bg-color: $accent-color;
$tab-label-icon-bg-color: $text-primary-color;
$tab-label-active-icon-bg-color: $text-primary-color;

// Buttons
$button-primary-fg-color: #ffffff;
$button-primary-bg-color: $accent-color;
$button-secondary-bg-color: transparent;
$button-danger-fg-color: #ffffff;
$button-danger-bg-color: $notice-primary-color;
$button-danger-disabled-fg-color: #ffffff;
$button-danger-disabled-bg-color: #f5b6bb; // TODO: Verify color
$button-link-fg-color: $accent-color;
$button-link-bg-color: transparent;

// Toggle switch
$togglesw-off-color: #454854;

$visual-bell-bg-color: #800;

$room-warning-bg-color: $header-panel-bg-color;

$dark-panel-bg-color: $header-panel-bg-color;
$panel-gradient: rgba(34, 38, 46, 0), rgba(34, 38, 46, 1);

$message-action-bar-bg-color: $header-panel-bg-color;
$message-action-bar-fg-color: $header-panel-text-primary-color;
$message-action-bar-border-color: #616b7f;
$message-action-bar-hover-border-color: $header-panel-text-primary-color;

$reaction-row-button-bg-color: $header-panel-bg-color;
$reaction-row-button-border-color: #616b7f;
$reaction-row-button-hover-border-color: $header-panel-text-primary-color;
$reaction-row-button-selected-bg-color: #1f6954;
$reaction-row-button-selected-border-color: $accent-color;
$reaction-row-button-error-bg-color: $siren-180;
$reaction-row-button-error-border-color: $siren-100;

$kbd-border-color: #000000;

$tooltip-timeline-bg-color: $tagpanel-bg-color;
$tooltip-timeline-fg-color: #ffffff;

$interactive-tooltip-bg-color: $base-color;
$interactive-tooltip-fg-color: #ffffff;

$breadcrumb-placeholder-bg-color: #272c35;

$user-tile-hover-bg-color: $header-panel-bg-color;

// Appearance tab colors
$appearance-tab-border-color: $room-highlight-color;

// blur amounts for left left panel (only for element theme, used in _mods.scss)
$roomlist-background-blur-amount: 60px;
$tagpanel-background-blur-amount: 30px;

$composer-shadow-color: rgba(0, 0, 0, 0.28);

// ***** Mixins! *****

@define-mixin mx_DialogButton {
    /* align images in buttons (eg spinners) */
    vertical-align: middle;
    border: 0px;
    border-radius: 8px;
    font-family: $font-family;
    font-size: $font-14px;
    color: $button-fg-color;
    background-color: $button-bg-color;
    width: auto;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    cursor: default;
    display: inline-block;
    outline: none;
}

@define-mixin mx_DialogButton_danger {
    background-color: $accent-color;
}

@define-mixin mx_DialogButton_secondary {
    // flip colours for the secondary ones
    font-weight: 600;
    border: 1px solid $accent-color !important;
    color: $accent-color;
    background-color: $button-secondary-bg-color;
}

@define-mixin mx_Dialog_link {
    color: $accent-color;
    text-decoration: none;
}

// Nasty hacks to apply a filter to arbitrary monochrome artwork to make it
// better match the theme.  Typically applied to dark grey 'off' buttons or
// light grey 'on' buttons.
.mx_filterFlipColor {
    filter: invert(1);
}

// markdown overrides:
.mx_EventTile_content .markdown-body pre:hover {
    border-color: #808080 !important; // inverted due to rules below
}
.mx_EventTile_content .markdown-body {
    table {
        tr {
            background-color: #000000;
        }

        tr:nth-child(2n) {
            background-color: #080808;
        }
    }

    blockquote {
        color: #919191;
    }
}

$left-panel-bg: #2e3038;

$divider-color: #25262d;

$event-tile-bg: #35363d;
$event-tile-fg: #f0f0f1;

$room-header-bg: #262931;
$header-button-hover: #fff;

$message-composer-bg: #25262d;
$message-composer-fg: #efeff0;
$message-composer-border: #76767b;

$mention-pill-bg: rgba(37, 38, 45, 0.4);
$mention-pill-fg: #efeff0;
$mention-pill-out-fg: #efeff0;
$at-me-pill-fg: $bronzer-500;
$composer-mention-pill-bg: #35363d;

$primary-hairline-color: #404040;

$search-bar-bg: #25262d;
$search-bar-input-bg: #202127;

$reply-thread-bg: rgba(37, 38, 45, 0.4);
$menu-bg-color: $primary-bg-color;

$dark-panel-bg-color: $primary-bg-color;

$menu-selected-color: $room-highlight-color;

$header-panel-bg-color: #454854;
$secondary-accent-color: $primary-bg-color;
$plinth-bg-color: $secondary-accent-color;
$selected-color: $secondary-accent-color;
$event-selected-color: $header-panel-bg-color;

$bp-tag-bg: $left-panel-bg;
$bp-tag-active: $search-bar-bg;

$room-list-preview-color: rgba(208, 209, 216, 0.8);

$invites-baloon-bg: rgba(255, 245, 219, 0.2);

$accent-fg-color: #202127;

$protocol-spaces-color: #d0d1d8;

$transparent-body-bg: #25262d25;

/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/*******************     BEEPER BEEKIT      ***********************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/

/******************************************************************/
/***********************   INPUT   ********************************/
/******************************************************************/

$bp-input-bgcolor: $dark-60;
$bp-input-hover-color: $dark-700;
$bp-input-placeholder-color: $dark-20;
$bp-input-textcolor: $space-01;
$bp-bordered-input-bgcolor: $primary-bg-color;
$bp-bordered-input-placeholder-color: $dark-20;
$bp-bordered-input-textcolor: $space-01;

/******************************************************************/
/***********************   DIVIDER   ******************************/
/******************************************************************/

$bp-divider-primary-color: $light-300;

/******************************************************************/
/***********************   BUTTON   *******************************/
/******************************************************************/

$bp-button-primary-textcolor: $dark-100;
$bp-button-primary-border: 0px solid transparent;

$bp-button-primary-bg: linear-gradient(120deg, #a93fe9 12px, #4958f5 502px);
$bp-button-hover-textcolor: $light-900;
$bp-button-hover-border: transparent;
$bp-button-primary-hover-shadow: 0px 4px 16px rgba(133, 73, 238, 0.24), 0px 2px 12px rgba(130, 74, 239, 0.24);

$bp-button-disabled-bg: $dark-600;
$bp-button-disabled-textcolor: $dark-700;

$bp-button-secondary-textcolor: $dark-300;
$bp-button-secondary-hover-textcolor: $dark-400;

$bp-button-secondary-outline: $dark-60;
$bp-button-secondary-active-outline: $dark-80;
$bp-button-secondary-disabled-textcolor: $dark-150;
$bp-button-secondary-disabled-outline: $dark-90;

/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/**************************     BEEPER      ***********************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/

$beeper-theme: dark;
$media-bg-color: $dark-800;

/******************************************************************/
/***********************   BridgedIcon   **************************/
/******************************************************************/

$bp-bridged-icon-bg-color: $dark-20;
$bp-hovered-bridged-icon-bg-color: $dark-20;
$bp-selected-bridged-icon-bg-color: $space-20;

/******************************************************************/
/***********************    LeftPanel    **************************/
/******************************************************************/

$bp-muted-border: #d0d1d850; // $dark-20 at opacity 50
$bp-space-filter-bg: transparent;
$bp-space-filter-border: rgba(208, 209, 216, 0.2);

$bp-new-msg-gradient: radial-gradient(100% 100% at 50% 0%, rgba(240, 240, 241, 0.28) 0%, rgba(46, 48, 56, 0.28) 91.15%),
    #373a43;
$bp-new-msg-border: $dark-150;
$bp-new-msg-icon: url("$(res)/img/new-message-dark.svg");

/******************************************************************/
/*********************    Beekit Icon    **************************/
/******************************************************************/

$bp-icon-gray: #76767b;

/******************************************************************/
/************************    Composer    **************************/
/******************************************************************/

$autocomplete-selected-bg: $dark-60;

$left-panel-v2-bg: #3f4047;
$search-input-bg: #35363c;
$search-input-border: #76767b;

$room-tile-fg: #ffffff;
$room-tile-fg-secondary: #b1bfd0;

$room-pill-bg: #35363d;
$room-pill-bg-hover: #25262d;
$room-pill-bg-active: #4f5056;
$room-pill-unread-highlight: #ffffff20;

/******************************************************************/
/************************    LeftPanel    **************************/
/******************************************************************/

$room-tile-bg-hover: #25262dcc;
$room-tile-bg-active: #4f5056;
$room-tile-bg-active-transparency: #ffffff50;
$favourite-bg: #5e5f64;
$tile-name-color: white;
$favourite-text-color: white;
$favourites-active-color: #25262d;
$tile-action-hover-bg: #24303f;

$tile-focus-bg: rgb(44 42 47);
$tile-hover-bg: #24303f;
$tile-active-hover: rgb(44 42 47);
$tile-preview-color: rgb(255 255 255 / 50%);

$timestamp-color: rgba(255, 255, 255, 0.33);
$expand-active-chats-color: #75767b;

$fav-icon-color: #76767b;
$muted-icon-color: #76767b;

$space-bar-fg: #949598;

$space-bar-button-bg: linear-gradient(208deg, #40414a 0%, #26272c 73.94%);
$space-bar-button-bg--redesign: #26272c;
$send-button-fg-disabled: #76767a;
$space-bar-filter: grayscale(1) brightness(1.35);

$space-bar-mask: white;
$badge-bg: #ffffff;
$badge-color: #000000;
$space-bar-mask-opacity: 0.33;
$space-bar-mask-hover-opacity: 0.66;
$space-bar-bg: rgb(255 255 255 / 7%);

$incoming-reply-thread-bg: rgba(37, 38, 45, 0.4);

$space-item-shadow: inset 0.89px -1.67433px 4px rgba(34, 35, 40, 0.2), inset 0.89px -1.67px 4px rgba(68, 69, 78, 0.8);
$space-item-border: 1px solid #4f5056;

$space-item-hover-shadow: 0px 0px 0px 2px #76767b, inset 0.890257px -1.67433px 2px #222328,
    inset 0.89px -1.67px 4px #44454e;
$space-item-hover-border: 2px solid #35363d;

$space-item-active-shadow: 0px 0px 0px 1px #76767b, inset 0.890257px -1.67433px 2px #222328,
    inset 0.89px -1.67px 4px #44454e;
$space-item-active-border: 1px solid #35363d;

$header-control-fg: hsla(215, 21%, 76%, 0.33);
$search-fg: hsla(215, 21%, 76%, 0.33);

$room-header-fg: #efeff0;

/******************************************************************/
/***********************    Room Toast    *************************/
/******************************************************************/

$room-toast-border: $siren-500;
$room-toast-bg: $siren-200;

/******************************************************************/
/*******************    New Chat Dropdown    **********************/
/******************************************************************/

$new-chat-bg: $dark-800;
$contact-label-bg: $dark-700;
$contact-label-textcolor: $dark-100;

/******************************************************************/
/*******************  New Conversation View  **********************/
/******************************************************************/

$main-split-bg: #1e1e1e;
$button-color: rgb(255 255 255 / 50%);
$composer-bg: rgba(255, 255, 255, 0.07);
$compose-input-color: #ffffff;
$compose-send-color: hsla(220, 83%, 62%, 1);
$header-bg: #37353a;
$name-text: rgb(255 255 255 / 80%);
$reaction-gap-light: hsla(var(--avatar-hue, var(--default-avatar-hue)), 5%, 30%);
$reaction-gap-dark: hsla(var(--avatar-hue, var(--default-avatar-hue)), 5%, 22%);
$search-container-fg: #ffffff;
$search-container-bg: rgb(255 255 255 / 7%);
$spacebar-badge-icon-bg: #ffffff;
$main-panel-bg: #1e1e1e;
$room-header-shadow: 0 4px 24px rgb(10 38 46 / 0%);
$body-fg: rgb(255 255 255);
$body-bg: hsl(260deg 11% 16% / 35%);
$rooms-list-subtitle-fg: #fff;
$date-separator-bg: rgb(255 255 255 / 7%);
$date-separator-fg: rgb(255 255 255 / 50%);
$resize-handle-bg: inset 0.5px 0 0 0 black;
$left-panel-v2-bg-no-transparency: #202020;
$left-panel-v2-bg-no-transparency--windows: $left-panel-v2-bg-no-transparency;
$read-receipt-fg: hsla(0, 0%, 100%, 0.5);
$message-action-bg:  rgba(255, 255, 255, 0.5);
$search-bg: rgb(255 255 255 / 7%);
$composer-icon-fill: hsla(0, 0%, 100%, 0.5);
$transparent-resize: hsl(0deg 0% 100% / 10%);
$read-receipt-bg: #34393a;

:root {
    --default-avatar-hue: 150;
    --message-color-saturation: 100%;
    --message-color-lightness: 90%;
    --message-color-opacity: 1;
    --message-bg-saturation: 100%;
    --message-bg-lightness: 70%;
    --message-bg-opacity: 0.07;
    --reply-bg-opacity: 0.07;
    --reply-bg-saturation: 46%;
    --reply-bg-lightness: 81%;
    --reply-to-color-saturation: 100%;
    --reply-to-color-lightness: 95%;
    --reply-to-color-opacity: 0.8;
    --sender-name-saturation: 100%;
    --sender-name-lightness: 95%;
    --timestamp-saturation: 29%;
    --timestamp-lightness: 80%;
    --reaction-saturation: 29%;
    --reaction-lightness: 72%;
    --reaction-opacity: 0.15;
    --add-reaction-saturation: 29%;
    --add-reaction-lightness: 80%;
    --read-receipt-fg: hsla(240, 100%, 93%, 0.5);
    --read-receipt-tooltip-fg: 88%;
    --archive-background: rgb(43, 41, 47);
    --archive-fg: white;
    --left-panel-bg: hsl(0deg 0% 100% / 0%);
    --main-panel-bg: $main-panel-bg;
    --main-panel-transparent-bg: #1e1e1e00;
    --search-primary: white;
    --base: 255, 255, 255;
    --message-actions-fg: #d0d1d8;
    --search-bar-input-fg: #76767b;
    --search-input-bg-experimental: rgba(118, 118, 128, 0.12);
    --header-control-fg: hsla(215, 21%, 76%, 0.33);
    --header-control-fg-hover: hsla(215, 21%, 76%, 0.63);
    --room-tile-bg-hover: #25262dcc;
    --primary-content: $primary-content;
    --primary-bg-color: $primary-bg-color;
    --primary-fg-color: $primary-fg-color;
    --input-border-color: $input-border-color;
    --brand-linear-gradient: $brand-linear-gradient;
    --dialog-bg-color: $background;
    --read-check-color: #FFFFFF;
    --read-check-color-bubble: #FFFFFF;
    --composer-bg: $composer-bg;

    --tooltipText: white;
    --tooltipBackground: $menu-bg-color;

    // SCSS var translations
    --dialog-title-fg-color: $dialog-title-fg-color;

    // Spring 2023 Space Bar in Side Panel 
    --space-bar-bg: #ffffff07;
    --space-bar-selected: #ffffff50;
    --space-bar-error-icon: url("$(res)/img/x-stroke-dark.svg");
    --floating-button-bg: #312D39;
    --left-panel-bg-no-transparency: #202020;
    --left-panel-v2-bg-no-transparency--windows: $left-panel-v2-bg-no-transparency;

    // Lightbox
    --lightbox-opacity: 0.85;
    --lightbox-bg-color: #000;
    --lightbox-icon-color: #8e99a4;

    // General Use Colors

    --base-900: #25262D;
    --base-800: #32343E;
    --base-700: #35363D;
    --base-600: #5C5D62;
    --base-500: #68686D;
    --base-400: #76767B;
    --base-300: #949598;
    --base-200: #E1E2E2;
    --base-150: #EFEFF0;
    --base-100: #F7F7F7;

    // Dark
    --siren-pale: #3F333A;
    --siren-full: #E04950;
    --azure-pale: #313646;
    --azure-full: #3577ff; 

    // Buttons
    --button-transparent-bg: rgba(247, 247, 247, 0.05);
    --button-transparent-fg: var(--base-300);
}

.mx_MatrixChat_wrapper--transparent-main-panel {
    --message-actions-fg: #ffffff87;
}

.mx_MatrixChat_wrapper:not(.mx_MatrixChat_wrapper--colorful) {
    --default-avatar-hue: 100;
    --message-color-saturation: 0%;
    --message-color-lightness: 100%;
    --message-bg-saturation: 7%;
    --message-bg-lightness: 88%;
    --reply-to-color-saturation: 10%;
    --sender-name-saturation: 0%;
    --sender-name-lightness: 100%;
    --timestamp-saturation: 10%;
    --timestamp-lightness: 100%;
    --reaction-lightness: 80%;
    --add-reaction-saturation: 10%;
    --add-reaction-lightness: 90%;
    --read-receipt-tooltip-fg: 100%;
}

.mx_MatrixChat_wrapper--transparent-main-panel.mx_MatrixChat_wrapper--colorful {
    --timestamp-saturation: 100%;
    --timestamp-lightness: 90%;
    --reaction-lightness: 80%;
    --add-reaction-saturation: 100%;
    --add-reaction-lightness: 90%;
}