.mx_ToastContainer .mx_Toast_toast, .mx_ToastContainer.mx_ToastContainer_stacked::before {
    background-color: $primary-bg-color;
}

.nv_RoomView_messagePanel_useStraitLayout .mx_EventTile_bubbleContainer .mx_EventTile_line {
    background: $bg-color;

    .mx_EventTileBubble.mx_CreateEvent {
        background-color: $event-tile-bg;
    }
}

.bp_SpaceBarItem_badge {
  &_icon {
    &.warning {
        background-image: url('$(res)/img/warning-stroke-dark.svg') !important;
    }
    &.error {
        background-image: url('$(res)/img/x-stroke-dark.svg') !important;
    }
  }
}

.bp_Avatar_bg {
  &_1 {
    background-color: $koolaid-60;
  }
  &_2 {
    background-color: $crayola-60;
  }
  &_3 {
    background-color: $coral-60;
  }
  &_4 {
    background-color: $petal-60;
  }
}

.mx_EventTile_body.markdown-body {
  & > code, pre {
      background-color: rgba(0, 0, 0, 0.65);
  }
}
